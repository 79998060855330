define("discourse/plugins/discourse-shared-edits/discourse/initializers/shared-edits-init", ["exports", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/plugin-api", "discourse/models/composer", "discourse/plugins/discourse-shared-edits/discourse/components/shared-edit-button"], function (_exports, _ajax, _ajaxError, _pluginApi, _composer, _sharedEditButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SHARED_EDIT_ACTION = "sharedEdit";
  function initWithApi(api) {
    _composer.SAVE_LABELS[SHARED_EDIT_ACTION] = "composer.save_edit";
    _composer.SAVE_ICONS[SHARED_EDIT_ACTION] = "pencil";
    customizePostMenu(api);
    const currentUser = api.getCurrentUser();
    api.addPostAdminMenuButton(attrs => {
      if (!currentUser?.staff && currentUser?.trust_level < 4) {
        return;
      }
      return {
        icon: "far-pen-to-square",
        className: "admin-toggle-shared-edits",
        label: attrs.shared_edits_enabled ? "shared_edits.disable_shared_edits" : "shared_edits.enable_shared_edits",
        action: async post => {
          const url = `/shared_edits/p/${post.id}/${post.shared_edits_enabled ? "disable" : "enable"}.json`;
          try {
            await (0, _ajax.ajax)(url, {
              type: "PUT"
            });
            post.set("shared_edits_enabled", !post.shared_edits_enabled);
          } catch (e) {
            (0, _ajaxError.popupAjaxError)(e);
          }
        }
      };
    });
    api.includePostAttributes("shared_edits_enabled");
    api.addPostClassesCallback(attrs => {
      if (attrs.shared_edits_enabled && attrs.canEdit) {
        return ["shared-edits-post"];
      }
    });
    api.modifyClass("component:scrolling-post-stream", Superclass => class extends Superclass {
      sharedEdit() {
        this.appEvents.trigger("shared-edit-on-post");
      }
    });
    api.modifyClass("model:composer", Superclass => class extends Superclass {
      get creatingSharedEdit() {
        return this.get("action") === SHARED_EDIT_ACTION;
      }
      get editingPost() {
        return super.editingPost || this.creatingSharedEdit;
      }
    });
    api.modifyClass("controller:topic", Superclass => class extends Superclass {
      init() {
        super.init(...arguments);
        this.appEvents.on("shared-edit-on-post", this, this._handleSharedEditOnPost);
      }
      willDestroy() {
        super.willDestroy(...arguments);
        this.appEvents.off("shared-edit-on-post", this, this._handleSharedEditOnPost);
      }
      _handleSharedEditOnPost(post) {
        const draftKey = post.get("topic.draft_key");
        const draftSequence = post.get("topic.draft_sequence");
        this.get("composer").open({
          post,
          action: SHARED_EDIT_ACTION,
          draftKey,
          draftSequence
        });
      }
    });
  }
  function customizePostMenu(api) {
    api.registerValueTransformer("post-menu-buttons", _ref => {
      let {
        value: dag,
        context: {
          post,
          buttonLabels,
          buttonKeys
        }
      } = _ref;
      if (!post.shared_edits_enabled || !post.canEdit) {
        return;
      }
      dag.replace(buttonKeys.EDIT, _sharedEditButton.default, {
        after: [buttonKeys.SHOW_MORE, buttonKeys.REPLY]
      });
      dag.reposition(buttonKeys.REPLY, {
        after: buttonKeys.SHOW_MORE,
        before: buttonKeys.EDIT
      });
      buttonLabels.hide(buttonKeys.REPLY);
    });

    // register the property as tracked to ensure the button is correctly updated
    api.addTrackedPostProperties("shared_edits_enabled");
  }
  var _default = _exports.default = {
    name: "discourse-shared-edits",
    initialize: container => {
      const siteSettings = container.lookup("service:site-settings");
      if (!siteSettings.shared_edits_enabled) {
        return;
      }
      (0, _pluginApi.withPluginApi)("1.39.2", initWithApi);
    }
  };
});