define("discourse/plugins/discourse-shared-edits/discourse/components/shared-edit-button", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/helpers/concat-class", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _concatClass, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SharedEditButton extends _component.default {
    static shouldRender(args) {
      return args.post.can_edit;
    }
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    get showLabel() {
      return this.args.showLabel ?? this.site.desktopView;
    }
    sharedEdit() {
      this.appEvents.trigger("shared-edit-on-post", this.args.post);
    }
    static #_3 = (() => dt7948.n(this.prototype, "sharedEdit", [_object.action]))();
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DButton
          class={{concatClass
            "post-action-menu__shared-edit"
            "shared-edit"
            "create fade-out"
          }}
          ...attributes
          @action={{this.sharedEdit}}
          @icon="far-pen-to-square"
          @label={{if this.showLabel "post.controls.edit_action"}}
          @title="shared_edits.button_title"
        />
      
    */
    {
      "id": "jc1rRsL3",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[16,0,[28,[32,1],[\"post-action-menu__shared-edit\",\"shared-edit\",\"create fade-out\"],null]],[17,1]],[[\"@action\",\"@icon\",\"@label\",\"@title\"],[[30,0,[\"sharedEdit\"]],\"far-pen-to-square\",[52,[30,0,[\"showLabel\"]],\"post.controls.edit_action\"],\"shared_edits.button_title\"]],null],[1,\"\\n  \"]],[\"&attrs\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-shared-edits/discourse/components/shared-edit-button.js",
      "scope": () => [_dButton.default, _concatClass.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = SharedEditButton;
});